import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/Page404.css";

export const Page404 = memo(() => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  const onClickWorks = () => navigate("/works");
  const onClickSkills = () => navigate("/skills");
  const onClickAbout = () => navigate("/about");
  return (
    <div className="page404">
      <div className="page404_title">404</div>
      <div className="errorText">
        <p className="en">404 not found</p>
        <br />
        <p className="ja">お探しのページは見つかりませんでした。</p>
      </div>
      <div className="page404_menu">
        <ul className="page404_items">
          <li className="top">
            <button onClick={onClickTop}>
              Top
              <br />▼
            </button>
          </li>
          <li className="works">
            <button onClick={onClickWorks}>
              Works
              <br />▼
            </button>
          </li>
          <li className="skills">
            <button onClick={onClickSkills}>
              Skills
              <br />▼
            </button>
          </li>
          <li className="about">
            <button onClick={onClickAbout}>
              About
              <br />▼
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
});
