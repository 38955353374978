import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/Skills.css";

export const Skills = memo(() => {
  const navigate = useNavigate();

  const techList = [
    "C/C++",
    "Flask",
    "Spring",
    "React",
    "React Native",
    "Laravel",
    "Sass",
    "Linux",
    "Git",
    "SQL",
    "AWS",
  ];

  const toolList = [
    "Visual Studio",
    "Visual Studio Code",
    "IntelliJ",
    "Google Colab",
    "JupyterNotebook",
    "WordPress",
    "Ubuntu",
    "Github",
    "Docker",
  ];

  const onClickAbout = () => navigate("/about");
  return (
    <div className="skills">
      <div className="pages_title">Skills</div>
      <div className="skills_contents">
        <div className="skills_text">
          　広い知識と経験から主にWebアプリケーション開発やセールスエンジニアを行っています。一般的なフロント / バックエンド技術からマイコンを利用するIoT技術まで幅広い開発経験があり、日本最大級のAI専門メディアでのインタビュー兼ライター経験からITに関する情報の知見があります。</div>
        <div className="content">
          <p>
            {"[ "}技術{" ："}
          </p>
          <p className="texts">
            {techList.map((tech, index) => (
              <span key={index}>{tech}</span>
            ))}
          </p>
          <p>{"]"}</p>
          <br />
          <p>{"["}ツール：</p>
          <p className="texts">
            {toolList.map((tool, index) => (
              <span key={index}>{tool}</span>
            ))}
          </p>
          <p>{"]"}</p>
        </div>
      </div>
      <div className="under_menu">
        <button onClick={onClickAbout}>
          About
          <br />▼
        </button>
      </div>
    </div>
  );
});
