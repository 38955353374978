import React from "react";
import { useNavigate } from "react-router-dom";

import "./styles/Header.css";

export const Header = () => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  return (
    <header>
      <button className="pop" onClick={onClickTop}>
        {"</>"}
      </button>
    </header>
  );
};
