import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";

import "./styles/HeroSwiper.css";
import "swiper/swiper-bundle.min.css";

import FreedomImg from "./images/freedomTopImg.png";
import GreenSalonImg from "./images/greensalonTopImg.png";
import LProImg from "./images/lproTopImg.png";
import TotoImg from "./images/totoTopImg.png";
import TrumpGameRouletteImg from "./images/trumpGameRouletteTopImg.png";

export const HeroSwiper = () => {
  const navigate = useNavigate();

  const onClickGreenSalon = () => navigate("/works/GreenSalon");
  const onClickLPro = () => navigate("/works/LPro");
  const onClickFreedom = () => navigate("/works/Freedom");
  const onClickTrumpGameRoulette = () => navigate("/works/TrumpGameRoulette");
  const onClickToto = () => navigate("/works/toto");

  SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);

  return (
    <div className="hero">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={2}
        loop={true}
        navigation={true}
        autoplay={{
          delay: 5000,
        }}
        speed={1000}
      >
        <SwiperSlide>
          <img src={GreenSalonImg} alt="" onClick={onClickGreenSalon} />
          <h2 id="greenSalon">GreenSalon</h2>
        </SwiperSlide>
        <SwiperSlide>
          <img src={LProImg} alt="" onClick={onClickLPro} />
          <h2>LPro</h2>
        </SwiperSlide>
        <SwiperSlide>
          <img src={FreedomImg} alt="" onClick={onClickFreedom} />
          <h2>Freedom</h2>
        </SwiperSlide>
        <SwiperSlide>
          <img src={TrumpGameRouletteImg} alt="" onClick={onClickTrumpGameRoulette} />
          <h2>TrumpGameRoulette</h2>
        </SwiperSlide>
        <SwiperSlide>
          <img src={TotoImg} alt="" onClick={onClickToto}/>
          <h2>学習塾トト</h2>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
