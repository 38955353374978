import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/About.css";
import MyIcon from "./images/myIcon.jpg";
import AboutImg from "./images/Portfolio_AboutMe.jpg";

export const About = memo(() => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  return (
    <div className="about">
      <div className="pages_title">About</div>
      <div className="about_contents">
        <div className="text_content">
          <div className="text_front">
            <p className="center">
              <p className="sub">ほりこし　てんち</p>
              <h1 className="main">堀越 天地</h1>
            </p>
            <p className="under">
              〒116-0002 東京都荒川区荒川
              <br />
              E-mail：he.tenchi.llo@gmail.com
            </p>
          </div>
          <div className="text_back">
            <div className="back_top">
              <img src={MyIcon} alt="" />
              <h1>Tenchi Horikoshi</h1>
            </div>
            <div className="back_under">
              <div className="under_left">
                <span>生年月日</span>
                <br />
                {"　"}2001/10/14
                <br />
                <span>趣味</span>
                <br />
                {"　"}映画/読書/音楽
              </div>
              <div className="line"></div>
              <div className="under_right">
                <span>活動拠点</span>
                <br />
                {"　"}東京
                <br />
                <span>活動内容</span>
                <br />
                {"　"}Web開発/技術営業
              </div>
            </div>
          </div>
        </div>
        <div className="img_content">
          <img src={AboutImg} alt="" />
        </div>
      </div>
      <div className="under_menu">
        <button onClick={onClickTop}>
          Top
          <br />▼
        </button>
      </div>
    </div>
  );
});
