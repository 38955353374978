import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactLoading from "react-loading";

import "./styles/App/App.css";
import { Header } from "./components/organisms/Header/Header";
import { Routing } from "./components/router/Routing";
import { Footer } from "./components/organisms/Footer/Footer";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isLoading: true }), 1400);
  }

  render() {
    return (
      <div className="App">
        {this.state.isLoading ? (
          <BrowserRouter>
            <Header />
            <Routing />
            <Footer />
          </BrowserRouter>
        ) : (
          <ReactLoading type="cylon" color="black" height={"10%"} width={"10%"} className="LoadingIcon"/>
        )}
      </div>
    );
  }
}

export default App;