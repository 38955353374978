import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/workCommon.css";
import FreedomImg from "./images/1_1_freedom_pc.png";

export const WorkFreedom = memo(() => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  const onClickWorks = () => navigate("/works");
  const onClickLPro = () => navigate("/works/LPro");
  const onClickTrumpGameRoulette = () => navigate("/works/TrumpGameRoulette");
  const onClickSkills = () => navigate("/skills");

  return (
    <div className="work_common">
      <div className="work_common_title">
        <span>
          <button className="pop" onClick={onClickTop}>
            {"</>"}
          </button>
        </span>
        <span className="p">
          <button className="work" onClick={onClickWorks}>
            Work
          </button>
        </span>
        <span className="s">
          <p className="a">{"｜"}</p>
        </span>
        <span className="p">
          <a href="#workTop">
            <p className="n">Freedom</p>
          </a>
        </span>
      </div>
      <div className="work_contents" id="workTop">
        <div className="content_text">
          <div className="text_left">
            <p>
              「Freedom」は実在する美容室です。
              <br />
              Freedomは美容室の中でも、昔ながらの雰囲気と植物が特長的なので、ホームページで店舗の内装をイメージ出来るようにデザインしました。動きのあるデザインの適用にはmixinを使用し、Loaderも実装することで直帰率を抑える工夫も取り入れました。
            </p>
          </div>
          <div className="text_right">
            <ul>
              <li className="h">技術</li>
              <li>HTML,CSS(Sass)</li>
              <li>JavaScript</li>
              <br />
              <li className="h">クレジット</li>
              <li>Sales：堀越 天地</li>
              <li>Design：畑 裕次郎</li>
              <li>Cooding：堀越 天地</li>
            </ul>
          </div>
        </div>
        <div className="content_main">
          <img src={FreedomImg} alt="" />
        </div>
        <div className="under_text">
          <p>
            店舗も有り、現在営業しています。
          </p>
        </div>
      </div>
      <button className="work_prev" onClick={onClickLPro}>
        {"＜"}
      </button>
      <button className="work_next" onClick={onClickTrumpGameRoulette}>
        {"＞"}
      </button>
      <div className="under_menu">
        <button onClick={onClickSkills}>
          Skills
          <br />▼
        </button>
      </div>
    </div>
  );
});
