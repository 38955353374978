import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/workCommon.css";
import TotoImg from "./images/5_1_toto_pc.png";

export const WorkToto = memo(() => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  const onClickWorks = () => navigate("/works");
  const onClickTrumpGameRoulette = () => navigate("/works/TrumpGameRoulette");
  const onClickGreenSalon = () => navigate("/works/GreenSalon");
  const onClickSkills = () => navigate("/skills");
  return (
    <div className="work_common">
      <div className="work_common_title">
        <span className="s">
          <button className="pop" onClick={onClickTop}>
            {"</>"}
          </button>
        </span>
        <span className="p">
          <button className="work" onClick={onClickWorks}>
            Work
          </button>
        </span>
        <span className="s">
          <p className="a">{"｜"}</p>
        </span>
        <span className="p">
          <a href="#tworkTop">
            <p className="n">学習塾トト(開発中)</p>
          </a>
        </span>
      </div>
      <div className="work_contents" id="workTop">
        <div className="content_text">
          <div className="text_left">
            <p>
            「学習塾トト」は文京区の個人塾トトの公式ホームページです。
              <br />
              学習塾トトは個人塾の中でも、アットホームか雰囲気と昔の寺子屋のようなイメージが特長的なので、ホームページで塾の雰囲気がイメージ出来るようにデザインしました。学習塾トトは現在開発中で常に内容が更新されることが多いので、WordPressで自作テーマを実装する予定です。
            </p>
          </div>
          <div className="text_right">
            <ul>
              <li className="h">技術</li>
              <li>HTML,CSS</li>
              <li>JavaScript</li>
              <li>PHP</li>
              <li>WordPress</li>
              <br />
              <li className="h">クレジット</li>
              <li>Sales：堀越 天地</li>
              <li>Design：畑 裕次郎</li>
              <li>Cooding：堀越 天地</li>
            </ul>
          </div>
        </div>
        <div className="content_main">
          <img src={TotoImg} alt="" />
        </div>
        <div className="under_text">
          <p>
            現在は学習塾トトが独自に実装したホームページが掲載されています。
            <br />
            この実装が完了次第、差し替える予定です。
            <br />
            公式ホームページ：
            <a href="https://gakushujuku-toto.com" target="_blank" rel="noreferrer noopener">千駄木 学習塾トト</a>
          </p>
        </div>
      </div>
      <button className="work_prev" onClick={onClickTrumpGameRoulette}>
        {"＜"}
      </button>
      <button className="work_next" onClick={onClickGreenSalon}>
        {"＞"}
      </button>
      <div className="under_menu">
        <button onClick={onClickSkills}>
          Skills
          <br />▼
        </button>
      </div>
    </div>
  );
});
