import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/Top.css";

export const Top = memo(() => {
  const navigate = useNavigate();

  const onClickWorks = () => navigate("/works");
  const onClickSkills = () => navigate("/skills");
  const onClickAbout = () => navigate("/about");
  return (
    <div className="top">
      <div className="top_title">
        <div className="top_title_main">
          Tenchi's{" < "}Portfolio{" >"}
        </div>
        <div className="top_title_line">
          <span></span>
        </div>
        <div className="top_title_sub">
          <span>Thank you for visiting.</span>
        </div>
      </div>
      <div className="top_menu">
        <ul>
          <li className="works">
            <button onClick={onClickWorks}>
              Works
              <br />▼
            </button>
          </li>
          <li className="skills">
            <button onClick={onClickSkills}>
              Skills
              <br />▼
            </button>
          </li>
          <li className="about">
            <button onClick={onClickAbout}>
              About
              <br />▼
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
});
