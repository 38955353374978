import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/workCommon.css";
import GreenSalonImg from "./images/2_1_greensalon_pc.png";

export const WorkGreenSalon = memo(() => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  const onClickWorks = () => navigate("/works");
  const onClickToto = () => navigate("/works/toto");
  const onClickLPro = () => navigate("/works/LPro");
  const onClickSkills = () => navigate("/skills");
  return (
    <div className="work_common">
      <div className="work_common_title">
        <span className="s">
          <button className="pop" onClick={onClickTop}>
            {"</>"}
          </button>
        </span>
        <span className="p">
          <button className="work" onClick={onClickWorks}>
            Work
          </button>
        </span>
        <span className="s">
          <p className="a">{"｜"}</p>
        </span>
        <span className="p">
          <a href="#workTop">
            <p className="n">GreenSalon</p>
          </a>
        </span>
      </div>
      <div className="work_contents" id="workTop">
        <div className="content_text">
          <div className="text_left">
            <p>
              「GreenSalon」はフロントエンド技術を学ぶために仮想で設定した美容室のホームページです。
              <br />
              このサイトはポップなイメージとお洒落なイメージを組み合わせることにより、幅広い層をターゲットにデザインしています。UIは全体の文字のバランスとボタンなのかテキストなのかを簡単に分かるように作成、配置しています。
            </p>
          </div>
          <div className="text_right">
            <ul>
              <li className="h">技術</li>
              <li>HTML,CSS</li>
              <li>JavaScript</li>
              <br />
              <li className="h">クレジット</li>
              <li>Design：畑 裕次郎</li>
              <li>Cooding：堀越 天地</li>
            </ul>
          </div>
        </div>
        <div className="content_main">
          <img src={GreenSalonImg} alt="" />
        </div>
        <div className="under_text">
          <p>
            このサイトは練習用であり、URLは有りません。実際にサイトを観覧したい場合はご連絡ください。
            <br />
            なお、写真が表示されていない部分が有りますが、練習用なので挿入していません。
          </p>
        </div>
      </div>
      <button className="work_prev" onClick={onClickToto}>
        {"＜"}
      </button>
      <button className="work_next" onClick={onClickLPro}>
        {"＞"}
      </button>
      <div className="under_menu">
        <button onClick={onClickSkills}>
          Skills
          <br />▼
        </button>
      </div>
    </div>
  );
});
