import React, { memo } from "react";
import { Route, Routes } from "react-router-dom";

import { Top } from "../pages/Top/Top";
import { Works } from "../pages/Works/Works";
import { WorkGreenSalon } from "../pages/Works/Work/WorkGreenSalon";
import { WorkLPro } from "../pages/Works/Work/WorkLPro";
import { WorkFreedom } from "../pages/Works/Work/WorkFreedom";
import { WorkTrumpGameRoulette } from "../pages/Works/Work/WorkTrumpGameRoulette";
import { WorkToto } from "../pages/Works/Work/WorkToto";
import { Skills } from "../pages/Skills/Skills";
import { About } from "../pages/About/About";
import { Page404 } from "../pages/Page404/Page404";

export const Routing = memo(() => {
  return (
    <Routes>
      <Route path="/" element={<Top />} />
      <Route path="/top" element={<Top />} />
      <Route path="/works" element={<Works />} />
      <Route path="/works/GreenSalon" element={<WorkGreenSalon />} />
      <Route path="/works/LPro" element={<WorkLPro />} />
      <Route path="/works/Freedom" element={<WorkFreedom />} />
      <Route
        path="/works/TrumpGameRoulette"
        element={<WorkTrumpGameRoulette />}
      />
      <Route path="/works/toto" element={<WorkToto />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/about" element={<About />} />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );
});
