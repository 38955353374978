import React, {memo} from "react";
import { HeroSwiper } from "./hero/HeroSwiper";
import { useNavigate } from "react-router-dom";

import "./styles/Works.css";

export const Works = memo(() => {
  const navigate = useNavigate();

  const onClickSkills = () => navigate("/skills");
  return (
    <div className="works">
      <div className="pages_title">Works</div>
      <div className="works_contents">
        <span className="slideshow">
          <HeroSwiper />
        </span>
      </div>
      <div className="under_menu">
        <button onClick={onClickSkills}>
          Skills
          <br />▼
        </button>
      </div>
    </div>
  );
});
