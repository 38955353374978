import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/workCommon.css";
import LProImg from "./images/3_1_lpro_pc.png";

export const WorkLPro = memo(() => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  const onClickWorks = () => navigate("/works");
  const onClickGreenSalon = () => navigate("/works/GreenSalon");
  const onClickFreedom = () => navigate("/works/Freedom");
  const onClickSkills = () => navigate("/skills");
  return (
    <div className="work_common">
      <div className="work_common_title">
        <span>
          <button className="pop" onClick={onClickTop}>
            {"</>"}
          </button>
        </span>
        <span className="p">
          <button className="work" onClick={onClickWorks}>
            Work
          </button>
        </span>
        <span className="s">
          <p className="a">{"｜"}</p>
        </span>
        <span className="p">
          <a href="#workTop">
            <p className="n">LPro</p>
          </a>
        </span>
      </div>
      <div className="work_contents" id="workTop">
        <div className="content_text">
          <div className="text_left">
            <p>
              「LPro」はフロントエンド技術を学ぶために仮想で設定したWeb制作会社のホームページです。
              <br />
              このサイトは固いイメージとお洒落なイメージを組み合わせることにより、会社としての仕事への取り組みとデザインの良さを強調しています。UIは内容が分かり易く見えるよう、バランス良くシンプルに実装しています。
            </p>
          </div>
          <div className="text_right">
            <ul>
              <li className="h">技術</li>
              <li>HTML,CSS</li>
              <li>JavaScript</li>
              <br />
              <li className="h">クレジット</li>
              <li>Design：畑 裕次郎</li>
              <li>Cooding：堀越 天地</li>
            </ul>
          </div>
        </div>
        <div className="content_main">
          <img src={LProImg} alt="" />
        </div>
        <div className="under_text">
          <p>
            このサイトは練習用であり、URLは有りません。実際にサイトを観覧したい場合はご連絡ください。
            <br />
            なお、実際の写真が無いため「PRODUCT.」には仮の写真を挿入しています。
          </p>
        </div>
      </div>
      <button className="work_prev" onClick={onClickGreenSalon}>
        {"＜"}
      </button>
      <button className="work_next" onClick={onClickFreedom}>
        {"＞"}
      </button>
      <div className="under_menu">
        <button onClick={onClickSkills}>
          Skills
          <br />▼
        </button>
      </div>
    </div>
  );
});
