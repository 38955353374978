import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import "./styles/workCommon.css";
import TrumpGameRouletteImg from "./images/4_1_trumpGameRoulette_pc.png";
import TrumpGameRouletteImg2 from "./images/4_2_trumpGameRoulette_pc.png";
import TrumpGameRouletteImg3 from "./images/4_3_trumpGameRoulette_pc.png";
import TrumpGameRouletteImg4 from "./images/4_4_trumpGameRoulette_pc.png";

export const WorkTrumpGameRoulette = memo(() => {
  const navigate = useNavigate();

  const onClickTop = () => navigate("/top");
  const onClickWorks = () => navigate("/works");
  const onClickFreedom = () => navigate("/works/Freedom");
  const onClickToto = () => navigate("/works/toto");
  const onClickSkills = () => navigate("/skills");
  return (
    <div className="work_common">
      <div className="work_common_title">
        <span className="s">
          <button className="pop" onClick={onClickTop}>
            {"</>"}
          </button>
        </span>
        <span className="p">
          <button className="work" onClick={onClickWorks}>
            Work
          </button>
        </span>
        <span className="s">
          <p className="a">{"｜"}</p>
        </span>
        <span className="p">
          <a href="#workTop">
            <p className="n">TrumpGameRoulette(開発中)</p>
          </a>
        </span>
      </div>
      <div className="work_contents" id="workTop">
        <div className="content_text">
          <div className="text_left">
            <p>
              「TrumpGameRoulette」は現在開発中のトランプゲーム抽選型Webアプリケーションです。
              <br />
              このWebアプリケーションでは、人数を選択しルーレットを回すとランダムでトランプのゲームが選ばれるシステムです。トランプとTrumpGameRouletteがあれば、無制限に遊ぶことができます。見た目をカジノに近づけ、どの画面でも楽しみながら使用できるように開発しています。
            </p>
          </div>
          <div className="text_right">
            <ul>
              <li className="h">技術</li>
              <li>HTML,CSS</li>
              <li>{"　"}styled-component,chakra-ui</li>
              <li>JavaScript</li>
              <li>{"　"}React</li>
              <br />
              <li className="h">クレジット</li>
              <li>Project：堀越 天地</li>
              <li>Design：堀越 天地</li>
              <li>Cooding：堀越 天地</li>
            </ul>
          </div>
        </div>
        <div className="content_main">
          <img src={TrumpGameRouletteImg} alt="" />
          <img src={TrumpGameRouletteImg2} alt="" />
          <img src={TrumpGameRouletteImg3} alt="" />
          <img src={TrumpGameRouletteImg4} alt="" />
        </div>
        <div className="under_text">
          <p>
            このサイトは仕様書の機能項目を全て実装したのち、再度機能について検討します。
            <br />
            追加機能の思案があれば随時実装し、最終的にリリースする予定です。
            <br />
            現段階ではドメインの取得は行っていません。
          </p>
        </div>
      </div>
      <button className="work_prev" onClick={onClickFreedom}>
        {"＜"}
      </button>
      <button className="work_next" onClick={onClickToto}>
        {"＞"}
      </button>
      <div className="under_menu">
        <button onClick={onClickSkills}>
          Skills
          <br />▼
        </button>
      </div>
    </div>
  );
});
